import React, { Component } from 'react';

class SkipQuestionButton extends Component {
  render() {
    const {
      recording,
      numberRecordings,
      data,
      showSkip,
      isMobile,
      screen_mode,
      skipQuestion
    } = this.props;

    // Extract category and subcategory names for easier reference
    const categoryName = data?.category?.name;
    const subCategoryName = data?.sub_category?.name || "";

    const isLegacy = categoryName === "Legacy";
    const isDating = categoryName === "Dating";

    const legacyCondition = subCategoryName !== "Interaction Facilitation Part 1 (required)";
    const datingCondition = subCategoryName !== "Interaction Facilitation & Pleasantries (required)";

    // Conditions for when to show the Skip button directly (no modal)
    const showLegacyNoRecording = !recording && numberRecordings === "" && isLegacy && legacyCondition;
    const showDatingNoRecording = !recording && numberRecordings === "" && isDating && datingCondition;

    // Conditions for when to show the Skip button that triggers a modal
    const showLegacyWithRecording = !recording && numberRecordings !== "" && isLegacy && legacyCondition;
    const showDatingWithRecording = !recording && numberRecordings !== "" && isDating && datingCondition;

    // Determine the appropriate className based on screen mode
    const buttonClass = isMobile && screen_mode === "portrait"
      ? "practice_portait_mode skip_button_create"
      : "skip_button_create";

    if (showLegacyNoRecording || showDatingNoRecording) {
      return (
        <h5 className="skip_ques_h5">
          <button
            disabled={!showSkip}
            className={buttonClass}
            onClick={(e) => skipQuestion(e)}
          >
            Skip Question
          </button>
        </h5>
      );
    }

    if (showLegacyWithRecording || showDatingWithRecording) {
      return (
        <h5 className="skip_ques_h5">
          <button
            disabled={!showSkip}
            className={buttonClass}
            data-toggle="modal"
            data-target="#myModal"
          >
            Skip Question
          </button>
        </h5>
      );
    }

    // If none of the conditions match, render nothing.
    return null;
  }
}

export default SkipQuestionButton;
