import React, { Component } from 'react';

class SkipModuleButton extends Component {
  render() {
    const {
      recording,
      numberRecordings,
      data,
      showSkip,
      completedata,
      skipModule
    } = this.props;

    // Extracting conditions into variables for clarity
    const isLegacy = data?.category?.name === "Legacy";
    const isDating = data?.category?.name === "Dating";
    const subCategoryName = data?.sub_category?.name || "";

    // Conditions for Legacy category
    const showLegacyNoRecording = !recording &&
      numberRecordings === "" &&
      isLegacy &&
      subCategoryName !== "Interaction Facilitation Part 1 (required)";

    const showLegacyWithRecording = !recording &&
      numberRecordings !== "" &&
      isLegacy &&
      subCategoryName !== "Interaction Facilitation Part 1 (required)";

    // Conditions for Dating category
    const showDatingNoRecording = !recording &&
      numberRecordings === "" &&
      isDating &&
      subCategoryName !== "Interaction Facilitation & Pleasantries (required)" &&
      completedata.current_module !== completedata.total_sub_module;

    const showDatingWithRecording = !recording &&
      numberRecordings !== "" &&
      isDating &&
      subCategoryName !== "Interaction Facilitation & Pleasantries (required)" &&
      completedata.current_module !== completedata.total_sub_module;

    // Render conditions:
    // For "no recording" scenarios, we use onClick to skipModule
    // For "with recording" scenarios, we use a modal trigger
    if (showLegacyNoRecording || showDatingNoRecording) {
      return (
        <button
          disabled={!showSkip}
          className="skipe_o2 skip_button_create"
          onClick={skipModule}
        >
          Skip Module
        </button>
      );
    }

    if (showLegacyWithRecording || showDatingWithRecording) {
      return (
        <button
          disabled={!showSkip}
          className="skipe_o2 skip_button_create"
          data-toggle="modal"
          data-target="#myModalModule"
        >
          Skip Module
        </button>
      );
    }

    // If none of the conditions match, render nothing
    return null;
  }
}

export default SkipModuleButton;
